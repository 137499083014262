@import "../css/light.min.css";
@import "../css/brands.min.css";
@import "../css/regular.min.css";
@import "../css/solid.min.css";
/* @import "../css/duotone.min.css"; */
input,
textarea,
select,
ol,
ul,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  text-decoration: none;
  outline: none;
}
a,
a:hover,
a:focus,
*:focus-visible {
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  text-decoration: none;
  outline: none;
}
button:focus {
  outline: none;
  border: none;
}
button:focus,
input:focus {
  box-shadow: none !important;
}
button {
  cursor: pointer;
}
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ls li {
  list-style: none;
}
.cur {
  cursor: pointer;
}
.po img {
  opacity: 0;
}

.maxw img {
  max-width: 100%;
}
.g-center {
  place-items: center;
  display: grid;
}
.fulw img {
  width: 100%;
}

.rel {
  position: relative;
}
.ofit img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.mac-select {
  position: relative;
}
.mac-select select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  border: none;
  background: #fff;
}
.mac-select:after {
  content: "";
  z-index: 3;
  top: 54%;
  right: 8px;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 0, 0, 0);
  border-top-color: #000000;
  border-width: 5px;
  margin-left: -10px;
  transform: translateY(-50%);
}

.main-theadbox {    display: grid; 
  grid-auto-columns: 1fr; 
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "t1 t2 t3 t4 t5 t6 t7 t8 t9"; 
}

.t1 { grid-area: t1; }

.t2 { grid-area: t2; }

.t3 { grid-area: t3; }

.t4 { grid-area: t4; }

.t5 { grid-area: t5; }

.t6 { grid-area: t6; }

.t7 { grid-area: t7; }

.t8 { grid-area: t8; }

.t9 { grid-area: t9; }


.main-theadbox > div {
  padding: 0px;
  display: grid;
    place-items: center;
  border: 1px solid #ccc;
}
.main-theadboxes {    display: grid; 
  grid-auto-columns: 1fr; 
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    "t1 t2 t3 t4 t5 t6 t7 t8 t9"; 
}
.main-theadboxes > div {
  padding: 0;
  display: grid;
    place-items: center;
  border: 1px solid #ccc;
  /* width: 71px; */
}
.main-date-scroll {
  height: 360px;
  overflow-y: auto;
}
h6.iconroster.text-center.m-0 {
  padding: 10px;
  font-size: 14px;
}
.main-theadbox > div > h6 {
  padding: 8px 6px;   
  word-break:break-all;
}
@media (max-width: 600px) {
.signimg canvas {
  width: 100%;
}
}
/* .main-theadbox {
  margin-right: 15px;
} */
.divimg{
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.mob_table table {
  width: 100%;
  border-collapse: collapse;
}

.mob_table th, .mob_table td {
  border: 1px solid #ddd;
  padding: 0px;
  text-align: left;
}
.mob_table td {
  font-size: 12px;
  padding: 5px 0px;
  text-align: center;
}
.mob_table th{
  font-size: 12px;
  padding: 10px 0px;
  text-align: center;
}
.mob_table {
  height: 360px;
  overflow-y: scroll;
}
.imgurl {
  height: 500px;
}
.crosimg {
  border: 1px solid red;
  border-radius: 20px;
  padding: 0px 5px;
  background-color: red;
}
a.imgcross {
  font-size: 20px;
  color:white;
}
.timesheet {
  background: lightgray;
}
.maintimesheet {
  overflow-y: scroll;
  height: 209px;
  width: fit-content;
}

/* Define styles for mobile view */
a.logo-top.newad {
  width: 130px;
  margin: 0 25px;
}
.date-box {
  border-spacing: 15px;
}
.date-box, .date-box th, .date-box td {
  padding: 5px;
}
.fs-12{
  font-size: 12px;
}
.dbox {
	position: absolute;
	top: 53px;
	width: 116px;
}
.rbc-event {
	background: none !important;
}
.rbc-event:focus{
  background: #ccc !important;
  border: none !important;
  outline: none !important;
}
@media (max-width: 767px) {
  textarea.addany {
    width: 100%;
  
  }
  .maintimesheet {
   
    height: 143px;
  
  }
  }