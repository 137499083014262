@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap");
body {
  margin: 0;
  padding: 0;
  line-height: normal;
  overflow-x: hidden;
  height: 100vh;
  font-family: "Josefin Sans", sans-serif !important;
}
.look{
  display: flex;
  flex-direction: column;
  height: 100%;
}
footer{
  margin-top: auto;
}
.mybg-primary {
  background: #cc202e;
}

.mytext-primary {
  color: #cc202e;
}

.mybg-secondary {
  background: #2b3766;
}

.mytext-secondary {
  color: #2b3766;
}

.d-grid-center {
  display: grid;
  place-items: center;
}

header {
  margin: 0;
  padding: 8px 0px;
  background: #fff;
}
header .logo-top img {
  width: 100%;
}
header .navbar-list button {
  color: #000;
  font-size: 16px;
}
header .navbar-list a {
  color: #000;
  font-size: 16px;
}
header .navbar-list .active {
  color: #cc202e;
}

.sticky {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  right: 0;
  background: #fff;
  animation: mymove 1s ease-in-out;
}

@keyframes mymove {
  from {
    top: -50px;
  }
  to {
    top: 0px;
  }
}
.fullscreen {
  padding: 60px 0;
  background: url(../images/bg.jpg) no-repeat center;
  background-size: cover;
}
.fullscreenadmin {
  padding: 60px 0;
  background: url(../images/bg11.jpg) no-repeat center;
  background-size: cover;
}

.form-box textarea,
.form-box select,
.form-box input[type="text"],
.form-box input[type="date"],
.form-box input[type="number"],
.form-box input[type="password"],
.form-box input[type="email"],
.form-box input[type="tel"] {
  font-size: 14px;
  border-radius: 10px !important;
  border: none;
  color: #000;
  padding: 10px 10px 10px 40px;
  width: 100%;
  border: 1px solid transparent;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.form-box input[type="text"]:focus,
.form-box input[type="password"]:focus,
.form-box input[type="email"]:focus,
.form-box input[type="tel"]:focus {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.form-box .top-icon {
  position: absolute;
  z-index: 4;
  top: 13px;
  font-size: 14px;
  left: 10px;
  color: gray;
}

.forgot-pass {
  font-size: 14px;
  font-weight: 600;
  color: #cc202e;
}

.submit-btn {
  background: #cc202e;
  color: #fff;
  font-size: 14px;
  border-radius: 6px !important;
  padding: 10px;
  border: none;
  width: 100%;
  text-align: center;
}
.submit-btn:hover {
  background: #b61d29;
}

.reg-box {
  background: rgba(43, 55, 102, 0.7);
}

.btn-red-block a {
  background: #cc202e;
  color: #fff;
  font-size: 16px;
  padding: 8px 30px;
  width: 100%;
  text-align: center;
  display: block;
}
.btn-red-block a:hover {
  background: #a01924;
  color: #fff;
}

.innerbanner {
  background: #cc202e;
}

.pic-emp {
  margin-top: -50px;
}

.emplo-box-pic {
  width: 100px;
  height: 100px;
  border-radius: 100% !important;
  overflow: hidden;
}

.client-btn-text {
  background: #ccc;
  color: #000;
  text-align: center;
  padding: 15px 10px;
}
.client-btn-text:hover {
  color: #000;
  background: #b3b3b3;
}

.client-btn-text.client-active {
  background: #e86a75;
}

.sikll-text-head {
  background: #ccc;
  color: #000;
  text-align: center;
  font-size: 14px;
  padding: 10px;
}
.sikll-text-head:after {
  position: absolute;
  content: "";
  content: ",";
}

.scroll-date-block {
  overflow-x: auto;
  width: 320px;
  padding: 10px 0;
}
@media only screen and (max-width: 767.98px) {
  header .navbar-list a{
    font-size: 12px;
  }
  }
@media only screen and (max-width: 767.98px) {
  .scroll-date-block {
    width: 170px;
  }
}
.scroll-date-block h5 {
  font-size: 14px;
  color: #000;
  position: relative;
}
.scroll-date-block h5:after {
  position: absolute;
  content: "";
  content: ",";
}

.user-icon-top {
  border: 1px solid #999999;
  overflow: hidden;
  width: 30px;
  height: 30px;
  border-radius: 100% !important;
} /*# sourceMappingURL=main.css.map */

.logout {
  background: #e86a75;
  color: #fff !important;
  border: 1px solid #e86a75;
  padding: 10px;
  border-radius: 4px;
}

.formbox label {
  float: left;
}

.custom-date-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.special-icon {
  font-size: 24px; /* Adjust the icon size as needed */
  color: #ff0000; /* Change the icon color */
}

.special-title {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  margin-top: 5px;
}
.ft-topbox{
  background: #111;
}
.ft-boxes h3{
  color: #fff;
  font-size: 17px;
  font-weight: 600;
}
.ft-links p, .ft-links p a{
  color: #fff;
  font-size: 14px;
}
.copy-ft{
  background: #fff;
}
.copy-ft p{
  color: #111;
  font-size: 16px;
}
section.innerbanner.text-center.py-5.d-block.bgcolor {
  background: #18147D;
}