/*!
 * Font Awesome Pro 5.13.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(../fonts/fa-brands-400.eot);
  src: url(../fonts/fa-brands-400.eot?#iefix) format("embedded-opentype"),
    url(../fonts/fa-brands-400.woff2) format("woff2"),
    url(../fonts/fa-brands-400.woff) format("woff"),
    url(../fonts/fa-brands-400.ttf) format("truetype"),
    url(../fonts/fa-brands-400.svg#fontawesome) format("svg");
}
.fab {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}
